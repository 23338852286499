/********** Don't change the this file*********/
const axios = require('axios'); //Need to install axios in your local

module.exports = {
    importTransformedIamges: imageParams=>{ //Should enter proper source path
        imageParams.type = 'import';
        imageParams.force = imageParams.force || false;
        return performAction(imageParams);
    },
    
    getImageTransformedURL: imageParams=>{ //Should enter proper source path
        delete imageParams.type;
        imageParams.force = false;
        return performAction(imageParams);
    },

    getImageTransformedURLs: imageParams=>{ //Should enter proper source path images
        imageParams.type = 'multiple';
        imageParams.force = false;
        return performAction(imageParams);
    }
}

const performAction = imageParams =>{
    return new Promise (async (resolve, reject) => {
        try {
            const options = {
                method: 'get',
                //url: path,
                baseURL: 'https://6dr0v2wlh4.execute-api.eu-west-2.amazonaws.com/beta', //this value should be get from env variable
                //responseType: 'json',
                headers: {
                    "x-sbtv-customer": 'ggfx-holroyds'
                }, //this value should be get from env variable
                params: imageParams
            }
            const { data } = await axios(options); 
            resolve(data);
        } catch (error) {
            console.log('image-transformer-client error', error);
            reject(error);
        }
    })
}
